<template>
  <div>
    <Logout v-if="showLogout" @closeLogout="showLogout = false" />
    <Aside v-if="showAside" @closeAside="showAside = false" v-click-outside="onClickOutside" />
    <header class="header playerheader pc" :class="tabletplayer" style="background: transparent; backdrop-filter: none">
      <div class="header_box">
        <div class="logo aplayz_logo">
          <SantaHat />
          <img src="/media/img/logo_white.svg" width="120" height="40" alt="" />
        </div>
        <div class="right_menu">
          <div class="shop_info" v-if="$store.state.spaceNm != null && isshowspacename">
            <img src="/media/img/header_shop.png" alt="" />
            <p style="margin-bottom: 0px" v-html="$store.state.spaceNm" />
          </div>
          <div v-if="!isUseKisok && isshowalarm" class="alarm_box">
            <router-link
              :to="{
                name: 'Notice0'
              }"
            >
              <img src="/media/img/alarm.png" v-if="$store.state.noticeCount === 0" alt="" />
              <img src="/media/img/alarm_icon_active.svg" v-else alt="" />
            </router-link>
          </div>
          <div class="toggle" @click="showAside = true">
            <img src="/media/img/toggle.svg" alt="" />
          </div>
        </div>
      </div>
    </header>
    <header class="header-mo playheader mo">
      <nav class="mo_nav">
        <ul class="mo_nav_list">
          <li class="mo_nav_list_item">
            <figure class="logo_m_ws">
              <SantaHatMo />
              <img class="logo_m_img" src="/media/img/webCastPlayer/logo_m_ws.svg" alt="" width="20" height="26.27" />
            </figure>
          </li>
          <li class="mo_aplayz_player_header_area" v-if="spacename">
            <p class="small_box">{{ spacename }}</p>
            <img
              @click="$emit('open-myspace')"
              src="/media/img/webCastPlayer/arrow_drop_down_circle.svg"
              alt="arrow_drop_down_circle"
            />
          </li>
          <li class="mo_nav_list_item dp_flex">
            <button class="alarm_btn">
              <router-link
                :to="{
                  name: 'Notice0'
                }"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.02783 19.7773C9.02783 20.5181 9.46026 21.9996 11.19 21.9996C12.9197 21.9996 13.3521 20.5181 13.3521 19.7773"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.00049 18.666H20.3788"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.62207 18.1113V10.3335C3.62207 7.92613 5.13558 3.11133 11.1896 3.11133C17.2436 3.11133 18.7572 8.2965 18.7572 10.8891V18.1113"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <ellipse
                    cx="17.6759"
                    cy="6.44444"
                    rx="4.32431"
                    ry="4.44444"
                    fill="#FF1150"
                    v-if="$store.state.noticeCount !== 0"
                  />
                </svg>
              </router-link>
            </button>
            <div class="mo_toggle" @click="showAside = true">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1506_12025)">
                  <path
                    d="M24 20L6 20L6 18L24 18L24 20ZM24 13L12 13L12 11L24 11L24 13ZM24 6L6 6L6 4L24 4L24 6Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1506_12025">
                    <rect width="24" height="24" fill="white" transform="translate(24 24) rotate(180)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </li>
        </ul>
      </nav>
    </header>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import Aside from '@/layout/aside/Aside.vue';
import vClickOutside from 'v-click-outside';
import VueCookies from 'vue-cookies';
import router from '@/router';
import dayjs from 'dayjs';
import Logout from '@/components/modal/Logout/Logout.vue';
import SantaHat from '@/components/player/SantaHat.vue';
import SantaHatMo from '@/components/player/SantaHatMo.vue';
export default defineComponent({
  name: 'HeaderPlayer',
  emits: ['open-myspace'],
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    Aside,
    Logout,
    SantaHat,
    SantaHatMo
  },
  props: {
    expireDate: String,
    spacePayAt: String,
    tabletplayer: {
      type: String,
      required: false,
      default: ''
    },
    isshowspacename: {
      type: Boolean,
      required: false,
      default: true
    },

    isshowalarm: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      showLogout: false,
      showAside: false,
      userStatus: true,
      DDay: null
    };
  },
  created() {
    if (this.expireDate) {
      this.endDay(this.expireDate) < 1 ? (this.DDay = 'day') : (this.DDay = this.endDay(this.expireDate));
    }
  },
  watch: {
    expireDate(val) {
      this.endDay(val) < 1 ? (this.DDay = 'day') : (this.DDay = this.endDay(val));
    }
  },
  mounted() {
    this.headerScroll();
    window.addEventListener('scroll', this.headerScroll);
  },
  methods: {
    headerScroll() {
      const pageOffsetY = window.pageYOffset;
      const headerEle = document.querySelector('.kisok_header');
      if (headerEle) {
        if (pageOffsetY > 1278) {
          headerEle.classList.add('active');
        } else {
          headerEle.classList.remove('active');
        }
      }
    },
    endDay(value) {
      const today = dayjs();

      const expireDate = dayjs(value);
      const endDay = expireDate.diff(today, 'day', true);
      return Math.floor(endDay);
    },
    linkPayment() {
      localStorage.setItem('spaceId', VueCookies.get('spaceId'));
      router.push({ name: 'SpacePayment' });
    },
    onClickOutside() {
      this.showAside = false;
    },
    selectAutoMusicSetting() {
      this.$router.push({ name: 'AutoPlay' }).catch(() => {});
    },
    goPlayer() {
      this.$router.push({ name: 'playerZero' }).catch(() => {});
    }
  },
  computed: {
    spacename() {
      return this.$store.getters.getSpaceNm;
    },
    isChristmasSeason() {
      const curDate = new Date().getTime();
      const christmasSeason = new Date('2023-12-31 23:59:59').getTime();

      if (curDate > christmasSeason) {
        return false;
      }

      return true;
    },
    isUseKisok() {
      const userId = VueCookies.get('userId');
      const limitList = ['user_004699', 'user_000236'];

      const findIdx = limitList.findIndex(item => item === userId);
      if (findIdx > -1) {
        return true;
      }

      return false;
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.headerScroll);
  }
});
</script>

<style scoped src="@/assets/css/player/playerheader.css"></style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
