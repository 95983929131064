<template>
  <div class="santahat_wrap">
    <lottie-comp :options="options" :width="60" :height="60" />
  </div>
</template>
<script>
import Lottie from 'vue-lottie';
import santahat from '@/assets/json/lottie/santa_hat.json';
export default {
  name: 'SantaHat',
  components: {
    'lottie-comp': Lottie
  },
  data() {
    return {
      anim: null,
      options: { animationData: santahat }
    };
  },
  methods: {}
};
</script>
<style scoped>
.santahat_wrap {
  position: absolute;
  top: -3px;
  left: -25px;
  transform: rotate(-28deg);
}
</style>
